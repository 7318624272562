// css-to-string-loader: transforms styles from css-loader to a string output

// Get the styles
var styles = require("!!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-loader/dist/cjs.js!./unibox.scss");

if (typeof styles === 'string') {
  // Return an existing string
  module.exports = styles;
} else {
  // Call the custom toString method from css-loader module
  module.exports = styles.toString();
}